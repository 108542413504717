import { 
  Component, 
  ViewContainerRef, 
  ViewChild,
  OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { NgxSpinnerService } from "ngx-spinner";

import { MenuItem } from '../toolbar/toolbar.component';
import { MenuOption } from '../menu/menu.component';
import { ApiService } from '../service/api.service';
import { ComponentsService } from '../service/components.service'

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  @ViewChild('container', { 
    static: true, read: ViewContainerRef }) 
  viewContainerRef: ViewContainerRef
  selectedMenuOption: MenuOption

  constructor(
    private apiService: ApiService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private cookieService: CookieService,
    private componentService: ComponentsService) {}

  ngOnInit() {
    const isLogged: boolean = this.cookieService.check('isLogged');
    if(!isLogged){
      this.router.navigate([`./login`]);
    }else{
      this.selectedMenuOption = MenuOption.DASHBOARD
      this.componentService.setRootViewContainerRef(this.viewContainerRef)
      this.componentService.chageComponent(this.selectedMenuOption)
    }
  }
  
  onToolbarOptionSelected(item: MenuItem){
    if(item === MenuItem.EXIT){
      this.finishSession()
    }
  }

  onMenuOptionSelected(item: MenuOption){
    this.componentService.chageComponent(item)
  }

  finishSession(){
    this.spinner.show(undefined, { fullScreen: true });
      this.apiService.logout().subscribe(
        (data) =>{
          this.removeCookiesAndGoToLogin()
        },
        (error) => {
          this.removeCookiesAndGoToLogin()
        });
  }

  removeCookiesAndGoToLogin(){
    this.spinner.hide();
    this.cookieService.deleteAll();
    this.router.navigate([`./login`]);
  }
}
