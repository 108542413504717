import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  HOST_URL = environment.apiUrl

  constructor(
    private httpClient: HttpClient, 
    private cookieService: CookieService) {}

  getHeaders(){
    return {
      'x-auth': this.cookieService.get('x-auth')
    }
  }

  public login(body: any){
    return this.httpClient.post(`${this.HOST_URL}/v1/users/session`, body);
  }

  public logout(){
    return this.httpClient.delete(`${this.HOST_URL}/v1/users/session`, { headers: this.getHeaders() });
  }
}