import { Component, Input, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.css']
})
export class ToolbarComponent{

  @Input() hasVisibleOptions = false;
  @Output() toolbarItemClick: EventEmitter<MenuItem> = new EventEmitter<MenuItem>();
  items = MenuItem

  constructor() {}

  public onClickOption(option: MenuItem){
    this.toolbarItemClick.emit(option);
  }
}

export enum MenuItem{
  NONE = 0,
  EXIT = 1
}