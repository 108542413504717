import { 
  ComponentFactoryResolver,
  ViewContainerRef,
  Injectable
} from '@angular/core';

import { MenuOption } from '../menu/menu.component';
import { DashboardComponent } from '../dashboard/dashboard.component'
import { NewsComponent } from '../news/news.component'
import { ProductsComponent } from '../products/products.component'
import { PetsComponent } from '../pets/pets.component'
import { UsersComponent } from '../users/users.component'

@Injectable()
export class ComponentsService {

  rootViewContainer: ViewContainerRef

  constructor(private factoryResolver: ComponentFactoryResolver) {}
  
  setRootViewContainerRef(viewContainerRef: ViewContainerRef) {
    this.rootViewContainer = viewContainerRef
  }

  chageComponent(option: MenuOption) {
    const factory = this.factoryResolver.resolveComponentFactory(
      (option == MenuOption.DASHBOARD) ? DashboardComponent : 
      (option == MenuOption.NEWS) ? NewsComponent : 
      (option == MenuOption.PRODUCTS) ? ProductsComponent : 
      (option == MenuOption.PETS) ? PetsComponent : UsersComponent
    )
    const component = factory.create(this.rootViewContainer.parentInjector)
    this.rootViewContainer.clear()
    this.rootViewContainer.insert(component.hostView)
  }
}
