import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DeviceDetectorService, DeviceInfo } from 'ngx-device-detector';
import { NgxSpinnerService } from "ngx-spinner";
import { CookieService } from 'ngx-cookie-service';

import { DialogsimpleComponent } from '../dialog/dialogsimple.component';
import { ApiService } from '../service/api.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  hidePassword = true;
  deviceInfo: DeviceInfo;
  email = new FormControl('', [Validators.required, Validators.email]);
  password = new FormControl('', [Validators.required]);

  constructor(
    public dialog: MatDialog, 
    private apiService: ApiService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private cookieService: CookieService,
    deviceService: DeviceDetectorService) {
      this.deviceInfo = deviceService.getDeviceInfo()
  }

  ngOnInit() {
    const isLogged: boolean = this.cookieService.check('isLogged');
    if(isLogged)
      this.router.navigate([`./home`]);
  }

  getErrorEmail() {
    return this.email.hasError('required') ? 'You must enter a value' :
           this.email.hasError('email') ? 'Not a valid email' : '';
  }

  getErrorPassword() {
    return this.email.hasError('required') ? 'You must enter a value' : '';
  }

  doLogin() {
    if(this.email.valid && this.password.valid){
      this.spinner.show(undefined, { fullScreen: true });
      this.apiService.login(this.getLoginObject()).subscribe(
        (data) =>{
          this.spinner.hide();
          this.cookieService.set('isLogged', 'true');
          this.cookieService.set('x-auth', data['data'].token);
          this.router.navigate([`./home`]);
        },
        (error) => {
          this.spinner.hide();
          this.showDialog(error.error.status.error)
        });
    }
  }

  getLoginObject(){
    return {
      email: this.email.value,
      password: this.password.value,
      uuid: this.deviceInfo.browser+this.deviceInfo.browser_version,
      platform: "web"
    }
  }

  showDialog(message: String){
    const dialogRef = this.dialog.open(DialogsimpleComponent, {
      width: '350px',
      data: {title: "Alerta", message}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }
}