import { Component, Output, Input } from '@angular/core';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent {

  @Output() menuItemClick: EventEmitter<MenuOption> = new EventEmitter<MenuOption>();
  @Input() selectedOption: MenuOption = MenuOption.DASHBOARD
  menuItem = MenuOption

  constructor() {}

  public onClickOption(option: MenuOption){
    if(option != this.selectedOption){
      this.selectedOption = option
      this.menuItemClick.emit(option);
    }
  }
  
  getButtonStyle(option: MenuOption){
    let style = {'opacity' : 
      (this.selectedOption == option) ? '1' : '0.4'}
    return style
  }
  
}

export enum MenuOption{
  DASHBOARD = 0,
  NEWS = 1,
  PRODUCTS = 2,
  PETS = 3,
  USERS = 4,
}
