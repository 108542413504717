import {Component, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

export interface DialogData {
    title: string;
    message: string;
}

@Component({
    selector: 'dialog-simple',
    templateUrl: 'dialogsimple.component.html',
    styleUrls: ['./dialogsimple.component.css']
})
export class DialogsimpleComponent {
  
    constructor(
      public dialogRef: MatDialogRef<DialogsimpleComponent>,
      @Inject(MAT_DIALOG_DATA) public data: DialogData) {}
  
    onClickAccept(): void {
      this.dialogRef.close();
    }
  
}